/**
 * Sorts the array by sorting keys, which are calculated for each element by the comparator function
 * @param array
 * @param comparator
 *
 * @example
 * sortBy(users, user => [user.age, user.name])
 * // does the same as
 * users.sort((a, b) => a.age - b.age || a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
 *
 * @note
 * Добавлено сравнение строк через Intl.Collator, со строгим указанием правила локали 'en', чтобы латиница была выше кириллицы
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
 */

interface SortByOptions {
  collatorLocale?: string
  reverse?: boolean
}

export function sortBy<T>(
  array: T[],
  comparator: (item: T) => (string | number)[],
  { collatorLocale = 'en', reverse = false }: SortByOptions = {},
) {
  const stringsComparator = new Intl.Collator(collatorLocale)
  return array.sort((a, b) => {
    const keysA = comparator(a)
    const keysB = comparator(b)

    for (let i = 0; i < Math.min(keysA.length, keysB.length); ++i) {
      const keysAValue = keysA[i]
      const keysBValue = keysB[i]
      if (typeof keysAValue === 'string' && typeof keysBValue === 'string') {
        const compareValue = stringsComparator.compare(keysAValue, keysBValue)
        if (compareValue) {
          return reverse ? -compareValue : compareValue
        }
      }
      if (keysAValue > keysBValue) return reverse ? -1 : 1
      if (keysAValue < keysBValue) return reverse ? 1 : -1
    }

    return keysA.length - keysB.length
  })
}
